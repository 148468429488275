<template>
  <div class="flex flex-col gap-6 pt-4 xl:pr-8">
    <div class="flex items-baseline">
      <h3>{{ $t('companySettingsTitle') }}</h3>
    </div>

    <TrailTabGroup
      v-model="selectedTab"
      :tabs="companySettingsTabs"
      tab-list-class="mb-4"
    >
      <template
        v-for="{ value, component } in companySettingsTabs"
        :key="value"
        #[value]
      >
        <component :is="component" />
      </template>
    </TrailTabGroup>
  </div>
</template>

<script setup lang="ts">
import { onMounted, computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { definePage, useRoute, useRouter } from 'vue-router/auto'
import { storeToRefs } from 'pinia'
import { usePermissionsStore } from '@/stores/permissions'
import SSO from '@/components/CompanySettings/SSO/SSO.vue'
import BrandSettings from '@/components/CompanySettings/BrandSettings/BrandSettings.vue'
import Branches from '@/components/CompanySettings/Branches/Branches.vue'

definePage({
  name: 'company-settings',
  meta: {
    requiresAuth: true,
  },
})

const { t } = useI18n()
const route = useRoute<'company-settings'>()
const router = useRouter()

const {
  canViewCompanySettings,
  canViewSSOSaml,
  canViewBrandSettingsList,
  canViewBranchSettingsList,
} = storeToRefs(usePermissionsStore())

const companySettingsTabs = computed(() =>
  [
    {
      value: 'sso',
      label: t('companySettingsTabSso'),
      permission: canViewSSOSaml,
      component: SSO,
    },
    {
      value: 'branches',
      label: t('companySettingsTabBranches'),
      permission: canViewBranchSettingsList,
      component: Branches,
    },
    {
      value: 'brand-settings',
      label: t('companySettingsTabBrandSettings'),
      permission: canViewBrandSettingsList,
      component: BrandSettings,
    },
  ].filter((tab) => tab.permission.value)
)
const selectedTab = computed({
  get: () => route.params.tab,
  set: (value) => router.push({ params: { tab: value } }),
})

onMounted(() => {
  const hasPermissionToAccessTab =
    !selectedTab.value ||
    companySettingsTabs.value.some((item) => item.value === selectedTab.value)
  if (!canViewCompanySettings.value || !hasPermissionToAccessTab) {
    router.push('/home')
  }
})
</script>
